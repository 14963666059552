import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import getGoogleStr from "../../helpers/getGoogleStr"
import formatAddress from "../../helpers/formatAddress"
import Compass from "../icons/compass"
import Table from "../icons/table"
import Phone from "../icons/phone"
import Beer from "../icons/beer"

export default function FooterContent({ data }) {
  return (
    <Container>
      <Columns>
        <Column>
          <Phone />
          <Title>Call Us</Title>
          <CTALink href={`tel: ${data.phone}`}>{data.phone}</CTALink>
        </Column>
        <Column>
          <Compass />
          <Title>Visit Us</Title>
          <CTALink
            target="_blank"
            rel="noopener"
            href={getGoogleStr(
              data.name,
              data.street,
              data.city,
              data.zip,
              data.state
            )}
          >
            {formatAddress(data.state, data.street, data.city, data.zip)}
          </CTALink>
          <BoatLink
            href="https://apps.apple.com/app/apple-store/id744920098?mt=8"
            target="_blank"
            rel="noopener"
          >
            Traveling by boat?
          </BoatLink>
        </Column>
        <Column>
          <Table />
          <Title>Book a table</Title>
          <Text>
            <ReserveBtn
              href="https://www.opentable.com/r/dockside-brewery-milford?corrid=e01e47ac-37ce-4319-b19b-8cdadbc8a02f&avt=eyJ2IjoyLCJtIjowLCJwIjowLCJzIjowLCJuIjowfQ&p=2&sd=2022-05-02T19%3A00%3A00"
              target="_blank"
              rel="noopener"
            >
              Reserve Table
            </ReserveBtn>
          </Text>
        </Column>
        <Column>
          <Beer width="40px" fill="#fff" />
          <Title>Brewery</Title>
          <Link style={{ marginTop: "1rem" }} to="/brewery">
            View Our Beers
          </Link>
        </Column>
      </Columns>
    </Container>
  )
}

const Container = styled.div`
  /* border-top: 2px solid #fff; */
  background: #014e71;
  color: white;
  padding: 1rem 2rem;
  @media (min-width: 1024px) {
    padding: 4rem 2rem;
  }
`

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  a {
    color: white;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`

const Column = styled.div`
  margin: 0.5rem auto;
  padding: 1rem;
  text-align: center;
  /* max-width: 300px; */
  width: 100%;
  :not(:last-child) {
    border-bottom: 2px solid white;
  }
  @media (min-width: 1024px) {
    padding: 1rem 2rem;
    :not(:last-child) {
      border-bottom: none;
    }
    :not(:last-child) {
      border-right: 2px solid white;
    }
    svg {
      width: 75px !important;
      margin-bottom: 1rem;
    }
  }

  @media (min-width: 420px) {
    display: ${({ mobileOnly }) => (mobileOnly ? "none" : "")};
  }
`

const Title = styled.h5`
  color: #fff;
  margin: 0 !important;
  margin-bottom: 1.25rem;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  @media (min-width: 1024px) {
    font-size: 2.25rem;
  }
`
const Text = styled.p`
  margin-top: 1rem;
  @media (min-width: 1024px) {
    font-size: 1.2rem;
  }
`
const CTALink = styled.a`
  color: white;
  display: inline-block;
  margin-top: 1rem;
  &:hover {
    color: ${({ theme }) => theme.secondary};
    transition: all 0.3s;
  }
`

const BoatLink = styled.a`
  color: #fff;
  &:hover {
    color: ${({ theme }) => theme.secondary};
    text-decoration: underline;
    transition: all 0.3s;
  }
`

const ReserveBtn = styled.a`
  color: #fff;

  &:hover {
    text-decoration: underline;
    color: #eee;
  }
`
