import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LogoGirl from "../assets/white-logo-girl.png"
import Anchor from "../assets/anchor.svg"
import NavDropdown from "./widgets/NavDropdown"
import NavDropdownMobile from "./widgets/NavDropDownMobile"
import EventsPopupModal from "../components/EventsPopupModal"
import SnagAShip from "./SnagAShip"

const Navigation = ({ isMobile }) => {
  const [showPopup, setShowPopup] = useState(false)

  const aboutNavDropdownLinks = {
    ctaName: "About",
    dropdownLinks: [
      // {
      //   ctaName: "Brewery",
      //   ctaLink: "/brewery",
      //   clickable: true,
      // },
      {
        ctaName: "About Us",
        ctaLink: "/about",
        clickable: true,
      },
      {
        ctaName: "Gallery",
        ctaLink: "/gallery",
        clickable: true,
      },
      {
        ctaName: "Tour The Venue",
        ctaLink: "/tour-the-venue",
        clickable: true,
      },
      {
        ctaName: "Team",
        ctaLink: "/team",
        clickable: true,
      },
      {
        ctaName: "Press",
        ctaLink: "/press",
        clickable: true,
      },
      //   {
      //     ctaName: "Blog",
      //     ctaLink: "/blog",
      //     clickable: true,
      //   },
    ],
  }

  const galleryDropdownLinks = {
    ctaName: "Gallery",
    dropdownLinks: [
      {
        ctaName: "Gallery",
        ctaLink: "/gallery",
      },
      {
        ctaName: "Tour The Venue",
        ctaLink: "/tour-the-venue",
      },
    ],
  }
  const clubsDropdownLinks = {
    ctaName: "Clubs",
    dropdownLinks: [
      {
        ctaName: "Mug Club",
        ctaLink: "/mug-club",
        clickable: true,
      },
    ],
  }

  const eventsDropdownLinks = {
    ctaName: "Events",
    dropdownLinks: [
      {
        ctaName: "Events",
        ctaLink: "/events",
        clickable: true,
      },
      //   {
      //     ctaName: "Dockside All Year",
      //     ctaLink: "/dockside-all-year",
      //     clickable: true,
      //   },
      //   {
      //     ctaName: "Oktoberfest 2024",
      //     ctaLink: "/oktoberfest",
      //     clickable: true,
      //   },
      //   {
      //     ctaName: "Dockside Golf 2024",
      //     ctaLink: "/golf",
      //     clickable: true,
      //   },
    ],
  }

  const privateEventsDropdownLinks = {
    ctaName: "Private Events",
    dropdownLinks: [
      {
        ctaName: "Private Events",
        ctaLink: "/private-events",
        clickable: true,
      },
      //   {
      //     ctaName: "View Our Igloos",
      //     ctaLink: "/reserve-an-igloo",
      //     clickable: true,
      //   },

      //   {
      //     ctaName: "Book Igloos",
      //     ctaLink: "https://www.opentable.com/r/dockside-brewery-milford",
      //     clickable: true,
      //   },
    ],
  }

  const contactDropdownLinks = {
    ctaName: "Contact",
    dropdownLinks: [
      {
        ctaName: "contact",
        ctaLink: "/contact",
      },
      {
        ctaName: "FAQ",
        ctaLink: "/faq",
      },
    ],
  }

  const menuDropdownLinks = {
    ctaName: "Menu",
    dropdownLinks: [
      {
        ctaName: "Beer",
        ctaLink: "/beer-list",

        clickable: true,
      },
      {
        ctaName: "Cocktails",
        ctaLink: "/drinks",
        clickable: true,
      },
      {
        ctaName: "Food",
        ctaLink: "/menu",
        clickable: true,
      },
    ],
  }
  const boatSlipsDropdownLinks = {
    ctaName: "Boat Slips",
    dropdownLinks: [
      {
        ctaName: "Dockwa",
        clickable: true,
        ctaLink:
          "https://dockwa.com/explore/destination/pwcyg5-dockside-marina-docksidebrewery?form=transient",
      },
      {
        ctaName: "Snag a Slip",
        clickable: true,
        ctaLink:
          "https://www.snagaslip.com/north-east/long-island-sound-marinas/dockside-marina-at-dockside-brewery",
      },
    ],
  }

  const orderOnlineDropdownLinks = {
    ctaName: "Order Online",
    dropdownLinks: [
      {
        ctaName: "Skytab",
        ctaLink: "https://online.skytab.com/dfd0b576794eac045c70005c9c894dc8",
        clickable: true,
      },
      {
        ctaName: "Uber Eats",
        ctaLink:
          "https://www.ubereats.com/store/dockside-brewery/dFJ6VS7DQdK-m6_8htZCJQ?diningMode=DELIVERY",
        clickable: true,
      },
      {
        ctaName: "Grubhub",
        ctaLink:
          "https://www.grubhub.com/restaurant/dockside-brewery-40-bridgeport-ave-milford/10009880",
        clickable: true,
      },
      {
        ctaName: "DoorDash",
        ctaLink:
          "https://www.doordash.com/store/dockside-brewery-milford-32622519",
        clickable: true,
      },
    ],
  }

  if (isMobile) {
    return (
      <MobileNav>
        <NavDropdownMobile navLinks={aboutNavDropdownLinks} />
        <NavDropdownMobile navLinks={menuDropdownLinks}></NavDropdownMobile>

        <NavDropdownMobile navLinks={eventsDropdownLinks}></NavDropdownMobile>

        {/* <NavDropdownMobile
          navLinks={privateEventsDropdownLinks}
        ></NavDropdownMobile> */}
        <NavLink>
          <Link to="/private-events">Private Events</Link>
        </NavLink>
        <NavDropdownMobile
          navLinks={boatSlipsDropdownLinks}
        ></NavDropdownMobile>
        <NavLink>
          <Link to="/contact">Contact</Link>
        </NavLink>
        <NavDropdownMobile navLinks={orderOnlineDropdownLinks} />

        <NavLink isCallToAction="secondary">
          <Link
            to="https://services.shift4.com/gc/DOCKSIDEBREWERY"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gift Cards
          </Link>
        </NavLink>
        <MobileNavLogo>
          <img src={LogoGirl} alt="Dockside Brewing logo girl" />
        </MobileNavLogo>
      </MobileNav>
    )
  }
  return (
    <>
      {showPopup ? (
        <EventsPopupModal setShowPopup={setShowPopup}></EventsPopupModal>
      ) : (
        ""
      )}

      <NavDesktop className={"is-hidden-touch navDesktop"}>
        <NavDropdown navLinks={aboutNavDropdownLinks} />
        <NavDropdown navLinks={menuDropdownLinks}></NavDropdown>
        <NavDropdown navLinks={eventsDropdownLinks}></NavDropdown>
        <NavLink>
          <Link to="/private-events">Private Events</Link>
        </NavLink>
        {/* <NavDropdown navLinks={privateEventsDropdownLinks}></NavDropdown> */}
        <NavLink>
          <Link to="/contact">Contact</Link>
        </NavLink>

        <NavLink>
          <Link to="/careers">Careers</Link>
        </NavLink>

        <NavDropdown navLinks={orderOnlineDropdownLinks}></NavDropdown>
      </NavDesktop>
    </>
  )
}

const MobileNavLogo = styled.div`
  position: absolute;
  top: 80px;
  right: 10px;
  z-index: 999;
  img {
    max-width: 175px;
  }
`

const DropdownContainer = styled.div`
  position: absolute;
  width: 300px;
  top: 30px;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
`

const NavDesktop = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* padding-right: 0.75rem; */
  margin: 0 auto;
  padding-top: 2rem;
  a {
    color: white;
    font-size: 0.85rem;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin: 0.25rem 0.5rem;
    @media (max-width: 1300px) {
      font-size: 0.85rem;
      margin: 0.25rem;
    }

    &:hover {
      /* border-bottom: 2px solid #027bb3; */
      transition: all 0.3s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:after {
        content: url(${Anchor});
        max-width: 10px;
        svg path {
          fill: #fff;
        }
      }
    }
  }
`

const NavLink = styled.div`
  position: relative;
  background: ${({ isCallToAction, theme }) =>
    isCallToAction ? theme[isCallToAction] : ""};
  margin: 0rem;
  font-weight: 600;
  padding: ${({ isCallToAction }) => (isCallToAction ? " .5rem 2rem;" : "")} a {
    margin: 0rem;

    &:hover {
      color: $secondary;
      transition: all 0.3s;
    }
  }
`

const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0 0 1.75rem;

  ${NavLink} {
    margin-bottom: 0.5rem;

    a {
      color: white;
      text-transform: uppercase;
      font-size: 1.25rem;
      width: 100%;
      margin-bottom: 0.25rem;
      &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.primary};
        transition: all 0.3s;
      }
    }
  }
`

export default Navigation
